<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2 class="center">Aracı Hizmet Sağlayıcılar</h2>

        <social />

        <div class="staticbox">
          <div>
            <div class="madde">
              <p>
                <router-link to="/iys/kanun" class="blue"
                  ><strong
                    >6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında
                    Kanun</strong
                  ></router-link
                >
                ve
                <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                  ><strong
                    >Ticari İletişim ve Ticari Elektronik İletiler Hakkında
                    Yönetmelik</strong
                  ></router-link
                >
                kapsamında aracı hizmet sağlayıcıların sistemlerini İYS’yle
                uyumlaştırma yükümlülüklerini yerine getirebilmesi için
                kullanılacak Aracı Hizmet Sağlayıcı (AHS) API uygulaması
                erişimine henüz başvurmamış aracı hizmet sağlayıcılar aşağıdaki
                adımları izleyebilirler.
              </p>
              <!-- <p>Aracı hizmet sağlayıcılar, AHS API üzerinden yönetmeliğin 11. maddesi kapsamında hizmet sağlayıcıların ticari
                elektronik ileti gönderimi öncesi gerekli kontrolleri yapabilecek, abonelik hattını kapatan alıcıları İYS’ye
                bildirebilecektir.</p> -->
              <p>
                Aracı hizmet sağlayıcıların AHS API dokümantasyonu, test ve
                canlı ortamlarına erişebilmesi için aşağıdaki bilgi ve
                belgelerin
                <a href="mailto:ahs@iys.org.tr" class="blue"
                  ><strong>ahs@iys.org.tr</strong></a
                >’ye göndermesi gerekmektedir.
              </p>

              <h3>Gerekli Bilgiler</h3>
              <ul>
                <li>İmzaya Yetkili Kişi TCKN</li>
                <li>İmzaya Yetkili Kişi Ad Soyad</li>
                <li>Yetkili Kişi E-Posta</li>
                <li>Yetkili Kişi Cep Telefonu</li>
                <li>Ticaret Unvanı</li>
                <li>MERSİS Numarası</li>
                <li>Vergi Kimlik Numarası</li>
                <li>Vergi Dairesi</li>
                <li>Web Sitesi</li>
                <li>İletişim Numarası</li>
                <li>İl</li>
                <li>İlçe</li>
                <li>İletişim Adresi</li>
              </ul>

              <h3>Yetkili İletişim Bilgileri</h3>
              <ul>
                <li>
                  İş Birimi Yetkilisi
                  <div class="desc">Süreçle ilgili iletişime geçilir</div>
                  <ul>
                    <li>Ad Soyad</li>
                    <li>E-posta adresi</li>
                    <li>Telefon numarası</li>
                  </ul>
                </li>
                <li>
                  Teknik Yetkili
                  <div class="desc">
                    Test ve canlı ortam bilgileri paylaşılır
                  </div>
                  <ul>
                    <li>Ad Soyad</li>
                    <li>E-posta adresi</li>
                    <li>Telefon numarası</li>
                  </ul>
                </li>
                <li>
                  Yetkilendirme E-posta Adresi
                  <div class="desc">
                    Bir hizmet sağlayıcının markası için aracı hizmet sağlayıcı
                    olarak yetkilendirildiğinizde veya mevcut yetki durumunuz
                    değiştirildiğinde İYS'den tarafınıza bilgilendirme e-postası
                    iletilecektir. Gönderilen yetkilendirme bildirimlerinin
                    gözden kaçırılmaması için grup e-posta adresi iletilmesini
                    öneririz.
                  </div>
                </li>
              </ul>

              <h3>Gerekli belgeler:</h3>
              <ul>
                <li>Vergi levhası</li>
                <li>İmza sirküleri</li>
                <li>Ticaret sicil gazetesi</li>
                <li>Faaliyet belgesi</li>
                <li>
                  5809 Sayılı Elektronik Haberleşme Kanunu kapsamında lisansı
                  (varsa)
                </li>
                <li>
                  IP Adresi Bildirim Formu (indirmek için
                  <a
                    @click="TRACK('AHS IP Adresi Bildirim Formu Indir')"
                    class="blue"
                    download
                    href="/doc/iys_araci_hizmet_saglayici_ip_adresi_bildirim_formu.docx"
                    target="_blank"
                    >tıklayınız</a
                  >)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "Homepage",
  components: { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    }
  }
};
</script>

<style scoped>
.desc {
  background: #eff2f7;
  padding: 6px 16px;
  border-radius: 5px;
  color: #7d7e86;
  font-size: 0.95rem;
}

.formcontainer {
  padding-top: 50px;
}

.madde p {
  margin-bottom: 10px;
}

.staticbox > div {
  padding: 1vw 1vw 0 1vw;
  margin: 0;
  width: 100%;
  text-align: left;
}

ul li {
  display: list-item !important;
  list-style: square;
  margin: 15px 20px;
  justify-content: flex-start;
  line-height: 1.4;
  width: 100%;
}

h3 {
  margin-top: 5vh;
}

.staticpage h1 {
  margin-bottom: 2vh;
  padding-bottom: 0;
}
</style>
